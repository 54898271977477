/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
$theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$blue-palette,
  ),
  typography: (
    brand-family: 'Roboto',
    bold-weight: 900
  ),
  density: (
    scale: -1
  )
));
html {
  // Apply the light theme by default
  @include mat.core-theme($theme);
  @include mat.button-theme($theme);
  @include mat.card-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.input-theme($theme);
}
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

.nav-icon {
  margin-right: 8px;
  color: #555;
}

.grey {
  color: #555;
}

.full-width {
  width: 100%;
}

.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 4px;
  font-size: 12px;
}

.active {
  border-right: 4px solid rgba(blue, 0.7);
  background-color: rgba(skyblue, 0.1);
}

.nav-item {
  margin: 18px 0px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.nav-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: small;
}

.org-name {
  display: flex;
  font-size: small;
  color: #308bd1;
}

.cursor-pointer {
  cursor: pointer;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  gap: 12px;
}

.toast-message {
  position: fixed;
  top: 64px;
  right: 90px;
  z-index: 99;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.felx-column {
  display: flex;
  flex-direction: column;
}